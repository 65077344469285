(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.net-sale.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.net-sale.js');
"use strict";


const {
  CurrencyCounter
} = svs.components.lbUi.currencyCounter;
const DrawCardNetSale = _ref => {
  let {
    children,
    currencyCounterUniqueId
  } = _ref;
  return React.createElement("div", {
    className: "pg_draw_card__net_sale pg_draw_card_component"
  }, React.createElement(CurrencyCounter, {
    amount: children,
    uniqueId: currencyCounterUniqueId
  }), ' kr');
};
setGlobal('svs.components.tipsen.drawCardComponents.DrawCardNetSale', DrawCardNetSale);

 })(window);