(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.draw-toggle-btn.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.draw-toggle-btn.js');
"use strict";


const {
  ReactButton: Button
} = svs.ui;
const {
  clx
} = svs.components.lbUtils;
const DrawCardToggleBtn = _ref => {
  let {
    className,
    onClick,
    isSelected,
    isSmallDrawCard
  } = _ref;
  const blockName = 'pg_draw_card__draw_toggle_btn';
  const classNames = clx(blockName, 'pg_draw_card_component', className);
  return React.createElement("div", {
    className: classNames,
    onClick: onClick
  }, React.createElement(Button, {
    block: true,
    disabled: isSelected,
    iconPosition: "center",
    inverted: true,
    size: 400
  }, isSelected ? "Vald".concat(isSmallDrawCard ? ' omgång' : '') : "V\xE4lj".concat(isSmallDrawCard ? ' omgång' : '')));
};
setGlobal('svs.components.tipsen.drawCardComponents.DrawCardToggleBtn', DrawCardToggleBtn);

 })(window);