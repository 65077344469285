(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.bet-events.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.bet-events.js');
"use strict";


const {
  BetEvents
} = svs.components.tipsen.betEvents;
const DrawCardBetEvents = _ref => {
  let {
    children,
    className,
    limitToNumber
  } = _ref;
  const classNames = ['pg_draw_card__bet_events', 'pg_draw_card_component'];
  className && classNames.push(className);
  return React.createElement("div", {
    className: classNames.join(' ')
  }, React.createElement(BetEvents, {
    betEvents: children,
    limitToNumber: limitToNumber
  }));
};
setGlobal('svs.components.tipsen.drawCardComponents.DrawCardBetEvents', DrawCardBetEvents);

 })(window);