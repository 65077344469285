(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/components/disabled-overlay.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/components/disabled-overlay.js');
"use strict";

const {
  useRef,
  useEffect
} = React;
const {
  urlMapping
} = svs.core;
const {
  clx
} = svs.components.lbUtils;
const DisabledOverlay = _ref => {
  let {
    children,
    isHorizontal,
    isMini = false
  } = _ref;
  const disabledRef = useRef();
  useEffect(() => {
    const ChangeDrawChild = disabledRef.current.querySelector('.pg_draw_card_section--exclude-from-overlay');
    if (ChangeDrawChild) {
      const rect = ChangeDrawChild.getBoundingClientRect();
      if (isHorizontal) {
        disabledRef.current.style.setProperty('--change-draw-width', "".concat(Math.round(rect.width), "px"));
      } else {
        disabledRef.current.style.setProperty('--change-draw-height', "".concat(Math.round(rect.height), "px"));
      }
    }
  }, [children, isHorizontal]);
  return React.createElement("div", {
    className: clx('pg_draw_card__disabled_overlay', {
      'pg_draw_card__disabled_overlay--mini': isMini,
      'pg_draw_card__disabled_overlay--horizontal': isHorizontal,
      'pg_draw_card__disabled_overlay--vertical': !isHorizontal
    }),
    ref: disabledRef
  }, children, React.createElement("div", {
    className: "pg_draw_card__disabled_overlay__message"
  }, React.createElement("p", {
    className: "pg_draw_card__disabled_overlay__message__text"
  }, "Omg\xE5ngen \xE4r tillf\xE4lligt st\xE4ngd."), React.createElement("p", {
    className: "pg_draw_card__disabled_overlay__message__text"
  }, "V\xE4lkommen \xE5ter."), React.createElement("p", {
    className: "pg_draw_card__disabled_overlay__message__link"
  }, React.createElement("a", {
    href: urlMapping.get('cancelledDrawsInfo')
  }, "Mer information h\xE4r"))));
};
setGlobal('svs.components.tipsen.drawCard.components.DisabledOverlay', DisabledOverlay);

 })(window);