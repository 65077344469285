(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.status.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.status.js');
"use strict";

const {
  clx
} = svs.components.lbUtils;
const DrawCardStatus = _ref => {
  let {
    children,
    isCancelled = false,
    isLive = false
  } = _ref;
  const blockName = 'pg_draw_card__status';
  const classNames = clx(blockName, {
    ["".concat(blockName, "--live")]: isLive,
    ["".concat(blockName, "--cancelled")]: isCancelled
  }, 'pg_draw_card_component');
  return React.createElement("div", {
    className: classNames
  }, children);
};
setGlobal('svs.components.tipsen.drawCardComponents.DrawCardStatus', DrawCardStatus);

 })(window);