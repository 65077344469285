(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.extra-amount.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.extra-amount.js');
"use strict";


const DrawCardExtraAmount = _ref => {
  let {
    children
  } = _ref;
  return React.createElement("div", {
    className: "pg_draw_card__extra_amount pg_draw_card_component"
  }, children, " kr");
};
setGlobal('svs.components.tipsen.drawCardComponents.DrawCardExtraAmount', DrawCardExtraAmount);

 })(window);