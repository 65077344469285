(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.change-draw.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.change-draw.js');
"use strict";


const DrawCardChangeDraw = _ref => {
  let {
    children
  } = _ref;
  return React.createElement("div", {
    className: "pg_draw_card__change_draw pg_draw_card_component"
  }, children);
};
setGlobal('svs.components.tipsen.drawCardComponents.DrawCardChangeDraw', DrawCardChangeDraw);

 })(window);