(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/draw-card.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/draw-card.js');
"use strict";


const {
  useMemo
} = React;
const {
  clx
} = svs.components.lbUtils;
const {
  DRAW_CARD_LAYOUTS
} = svs.components.tipsen.drawCard.constants;
const {
  DrawCardContext,
  withinDrawCardContext
} = svs.components.tipsen.drawCard.context;
const {
  DrawCardExtraAmount,
  DrawCardLeague,
  DrawCardNetSale,
  DrawCardBetEvents,
  DrawCardToggleBtn,
  DrawCardRegCloseTime,
  DrawCardStatus,
  DrawCardTitle,
  DrawCardChangeDraw
} = svs.components.tipsen.drawCardComponents;
const {
  useComposeDrawForDrawCard
} = svs.components.tipsen.drawCard;
const {
  TipsenLink
} = svs.components.tipsen.tipsen_link;
const {
  analytics
} = svs.components;
const DrawCard = _ref => {
  let {
    children,
    className,
    currentDraw,
    onChangeDraw = () => {},
    layout = DRAW_CARD_LAYOUTS.VERTICAL,
    waiAriaRole,
    branding
  } = _ref;
  const context = useMemo(() => ({
    layout
  }), [layout]);
  const blockName = 'pg_draw_card';
  const classNames = clx(blockName, {
    ["".concat(blockName, "--").concat(branding)]: branding,
    ["".concat(blockName, "--horizontal")]: layout === DRAW_CARD_LAYOUTS.HORIZONTAL
  }, className);
  const content = React.createElement(DrawCardContext.Provider, {
    value: context
  }, children);
  const composedDraw = useComposeDrawForDrawCard(currentDraw);
  const createDrawSearchParam = () => {
    const newHref = composedDraw.href;
    return newHref.replace('?', '');
  };
  const onClick = () => {
    analytics.trackEvent({
      category: "Nya tipsen",
      action: 'change_draw',
      opt_label: "".concat(composedDraw.title, "-").concat(composedDraw.drawNumber)
    });
    onChangeDraw();
  };
  return React.createElement(TipsenLink, {
    className: classNames,
    onClick: onClick,
    role: waiAriaRole,
    to: {
      pathname: '',
      search: "?".concat(createDrawSearchParam())
    }
  }, content);
};
DrawCard.ExtraAmount = withinDrawCardContext(DrawCardExtraAmount);
DrawCard.League = withinDrawCardContext(DrawCardLeague);
DrawCard.NetSale = withinDrawCardContext(DrawCardNetSale);
DrawCard.BetEvents = withinDrawCardContext(DrawCardBetEvents);
DrawCard.DrawToggleBtn = withinDrawCardContext(DrawCardToggleBtn);
DrawCard.RegCloseTime = withinDrawCardContext(DrawCardRegCloseTime);
DrawCard.Status = withinDrawCardContext(DrawCardStatus);
DrawCard.Title = withinDrawCardContext(DrawCardTitle);
DrawCard.ChangeDraw = withinDrawCardContext(DrawCardChangeDraw);
setGlobal('svs.components.tipsen.drawCard.DrawCard', DrawCard);

 })(window);