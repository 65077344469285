(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/draw-card-container.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/draw-card-container.js');
"use strict";


const {
  DrawCardLarge,
  DrawCardSmall,
  DrawCardMini,
  DrawCardTableRow
} = svs.components.tipsen.drawCard;
const {
  useComposeDrawForDrawCard
} = svs.components.tipsen.drawCard;
const {
  DRAW_CARD_VARIANTS
} = svs.components.tipsen.drawCard.constants;
const {
  If
} = svs.components.lbUtils.reactConditionals;
const {
  clx
} = svs.components.lbUtils;
const DrawCardContainer = _ref => {
  let {
    branding,
    cardClassName,
    containerClassName,
    countdownString,
    currentDraw,
    drawCardVariant,
    isListItem = false,
    isSelected,
    onChangeDraw,
    shouldShowChangeDrawButton = false
  } = _ref;
  const blockName = 'pg_draw_card_container';
  const classNames = clx(blockName, containerClassName);
  const composedDraw = useComposeDrawForDrawCard(currentDraw);
  return React.createElement("div", {
    className: classNames
  }, React.createElement(If, {
    condition: drawCardVariant === DRAW_CARD_VARIANTS.LARGE
  }, React.createElement(DrawCardLarge, {
    betEvents: composedDraw.betEvents,
    branding: branding,
    competitionName: composedDraw.competitionName,
    currentDraw: currentDraw,
    extraMoney: composedDraw.extraMoney,
    icon: composedDraw.icon,
    league: composedDraw.league,
    netSale: composedDraw.netSale,
    onChangeDraw: onChangeDraw,
    regCloseTime: composedDraw.regCloseTime,
    shouldShowChangeDrawButton: shouldShowChangeDrawButton,
    state: composedDraw.state,
    title: composedDraw.title,
    uniqueId: composedDraw.drawId
  })), React.createElement(If, {
    condition: !isListItem && drawCardVariant === DRAW_CARD_VARIANTS.SMALL
  }, React.createElement(DrawCardSmall, {
    betEvents: composedDraw.betEvents,
    branding: branding,
    competitionName: composedDraw.competitionName,
    currentDraw: currentDraw,
    extraMoney: composedDraw.extraMoney,
    icon: composedDraw.icon,
    league: composedDraw.league,
    netSale: composedDraw.netSale,
    onChangeDraw: onChangeDraw,
    regCloseTime: composedDraw.regCloseTime,
    shouldShowChangeDrawButton: shouldShowChangeDrawButton,
    state: composedDraw.state,
    title: composedDraw.title,
    uniqueId: composedDraw.drawId
  })), React.createElement(If, {
    condition: drawCardVariant === DRAW_CARD_VARIANTS.MINI
  }, React.createElement(DrawCardMini, {
    branding: branding,
    competitionName: composedDraw.competitionName,
    currentDraw: currentDraw,
    extraMoney: composedDraw.extraMoney,
    icon: composedDraw.icon,
    league: composedDraw.league,
    netSale: composedDraw.netSale,
    regCloseTime: countdownString || composedDraw.regCloseTime,
    state: composedDraw.state,
    title: composedDraw.title,
    uniqueId: composedDraw.drawId
  })), React.createElement(If, {
    condition: drawCardVariant === DRAW_CARD_VARIANTS.TABLE_ROW
  }, React.createElement(DrawCardTableRow, {
    betEvents: composedDraw.betEvents,
    branding: branding,
    className: cardClassName,
    competitionName: composedDraw.competitionName,
    currentDraw: currentDraw,
    extraMoney: composedDraw.extraMoney,
    icon: composedDraw.icon,
    isSelected: isSelected,
    league: composedDraw.league,
    netSale: composedDraw.netSale,
    onChangeDraw: onChangeDraw,
    regCloseTime: composedDraw.regCloseTime,
    state: composedDraw.state,
    title: composedDraw.title,
    uniqueId: composedDraw.drawId
  })), React.createElement(If, {
    condition: isListItem && drawCardVariant === DRAW_CARD_VARIANTS.SMALL
  }, React.createElement(DrawCardSmall, {
    betEvents: composedDraw.betEvents,
    branding: branding,
    className: cardClassName,
    competitionName: composedDraw.competitionName,
    currentDraw: currentDraw,
    drawNumber: composedDraw.drawNumber,
    extraMoney: composedDraw.extraMoney,
    href: composedDraw.href,
    icon: composedDraw.icon,
    isDisabled: composedDraw.isDisabled,
    isListItem: true,
    isSelected: isSelected,
    league: composedDraw.league,
    netSale: composedDraw.netSale,
    onChangeDraw: onChangeDraw,
    participants: composedDraw.participants,
    regCloseTime: composedDraw.regCloseTime,
    state: composedDraw.state,
    title: composedDraw.title,
    uniqueId: composedDraw.drawId
  })));
};
setGlobal('svs.components.tipsen.drawCard.DrawCardContainer', DrawCardContainer);

 })(window);