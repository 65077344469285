(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/components/draw-card-panel.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/components/draw-card-panel.js');
"use strict";


const {
  clx
} = svs.components.lbUtils;
const DrawCardPanel = _ref => {
  let {
    children,
    className,
    onClick,
    title
  } = _ref;
  const blockName = 'pg_draw_card__panel';
  const classNames = clx(blockName, {
    ["".concat(blockName, "--untitled")]: !title
  }, className);
  return React.createElement("div", {
    className: classNames,
    onClick: onClick
  }, title && React.createElement("div", {
    className: "pg_draw_card__panel__title"
  }, title), React.createElement("div", {
    className: "pg_draw_card__panel__content"
  }, children));
};
setGlobal('svs.components.tipsen.drawCard.components.DrawCardPanel', DrawCardPanel);

 })(window);