(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/hooks/use-conditional-wrapper.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/hooks/use-conditional-wrapper.js');
"use strict";


const {
  useMemo
} = React;
const useConditionalWrapper = (WrapperComponent, _ref) => {
  let {
    skip = false
  } = _ref;
  const WrappedComponent = useMemo(() => {
    const Component = _ref2 => {
      let {
        children
      } = _ref2;
      if (skip) {
        return children;
      }
      return React.createElement(WrapperComponent, null, children);
    };
    Component.displayName = "useConditionalWrapper(".concat(WrapperComponent.displayName || WrapperComponent.name || 'WrapperComponent', ")");
    return Component;
  }, [WrapperComponent, skip]);
  return WrappedComponent;
};
setGlobal('svs.components.tipsen.drawCard.useConditionalWrapper', useConditionalWrapper);

 })(window);