(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/draw-card-context.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/draw-card-context.js');
"use strict";

const {
  useContext,
  createContext
} = React;
const DrawCardContext = createContext();
const useDrawCardContext = () => {
  const drawCardContext = useContext(DrawCardContext);
  if (!drawCardContext) {
    throw new Error('DrawCard child component must be mounted inside DrawCardContext');
  }
  return drawCardContext;
};

const withinDrawCardContext = (WrappedComponent, componentName) => props => {
  const context = useContext(DrawCardContext);
  if (!context) {
    const wrappedComponentName = componentName || WrappedComponent.name;
    throw new Error("".concat(wrappedComponentName, " must be used within a DrawCard component"));
  }

  return React.createElement(WrappedComponent, props);
};
setGlobal('svs.components.tipsen.drawCard.context', {
  DrawCardContext,
  useDrawCardContext,
  withinDrawCardContext
});

 })(window);