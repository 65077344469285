(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.reg-close-time.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.reg-close-time.js');
"use strict";


const {
  formatDate
} = svs.components.lbUtils.helpers.formatDate;
const DrawCardRegCloseTime = _ref => {
  var _renderDate$charAt;
  let {
    children
  } = _ref;
  const date = new Date(children);
  const isValidDate = !isNaN(date);
  const renderDate = isValidDate ? formatDate(children, true, true, false) : children;
  const renderDataCapitalized = (renderDate === null || renderDate === void 0 || (_renderDate$charAt = renderDate.charAt(0)) === null || _renderDate$charAt === void 0 ? void 0 : _renderDate$charAt.toUpperCase()) + (renderDate === null || renderDate === void 0 ? void 0 : renderDate.slice(1));
  return React.createElement("div", {
    className: "pg_draw_card__reg_close_time pg_draw_card_component"
  }, renderDataCapitalized);
};
setGlobal('svs.components.tipsen.drawCardComponents.DrawCardRegCloseTime', DrawCardRegCloseTime);

 })(window);