(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.title.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.title.js');
"use strict";

const {
  ReactIcon
} = svs.ui;
const {
  clx
} = svs.components.lbUtils;
const DrawCardTitle = _ref => {
  let {
    children,
    icon,
    iconSize,
    isSelected = false
  } = _ref;
  const blockName = 'pg_draw_card__title';
  const classNames = clx('pg_draw_card__title', 'pg_draw_card_component', {
    ["".concat(blockName, "--selected")]: isSelected
  });
  return React.createElement("div", {
    className: classNames
  }, icon && React.createElement(ReactIcon, {
    className: "pg_draw_card__title__icon",
    icon: icon,
    size: iconSize
  }), React.createElement("div", {
    className: "pg_draw_card__title__content"
  }, children));
};
setGlobal('svs.components.tipsen.drawCardComponents.DrawCardTitle', DrawCardTitle);

 })(window);