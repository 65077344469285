(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/hooks/use-composed-draw-for-draw-card.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/hooks/use-composed-draw-for-draw-card.js');
"use strict";


const {
  getFundInfoToShow,
  getLeagueInfo,
  composeTitle,
  composeHref,
  useComposeBetEventsWithParticipants,
  getDrawState,
  getIconBySportType
} = svs.components.tipsen.drawCard.utils;
const {
  useSelector
} = ReactRedux;
const {
  useMemoizedSelector
} = svs.components.lbUtils.hooks;
const {
  makeSelectLeaguesAccumulatedFromDraw,
  makeSelectBetEventsFromDraw
} = svs.components.tipsen.selectors;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;
const useComposeDrawForDrawCard = function () {
  let draw = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    betIndex,
    currentNetSale,
    description,
    drawComment,
    drawNumber,
    drawStateId,
    productId,
    regCloseTime,
    sportType
  } = draw;
  const state = getDrawState(draw);
  const drawId = "".concat(productId, "_").concat(drawNumber);
  const selectLeaguesCountFromDraw = useMemoizedSelector(makeSelectLeaguesAccumulatedFromDraw, drawId);
  const leaguesAccumulatedInOrder = useSelector(selectLeaguesCountFromDraw);
  const league = getLeagueInfo(leaguesAccumulatedInOrder);
  const title = composeTitle({
    drawComment,
    description,
    productId,
    betIndex,
    regCloseTime
  });
  const selectBetEventsFromDraw = useMemoizedSelector(makeSelectBetEventsFromDraw, drawId);
  const betEvents = useSelector(selectBetEventsFromDraw);
  const composedBetEvents = useComposeBetEventsWithParticipants(betEvents, drawId);
  const compHandler = competitionHandler();
  const activeCompetition = compHandler.getActiveCompetition(productId);
  let competitionName;
  if (activeCompetition) {
    var _activeCompetition$ge, _activeCompetition$ge2;
    const isDrawIncludedInCompetition = ((_activeCompetition$ge = (_activeCompetition$ge2 = activeCompetition.getData()) === null || _activeCompetition$ge2 === void 0 || (_activeCompetition$ge2 = _activeCompetition$ge2.details) === null || _activeCompetition$ge2 === void 0 ? void 0 : _activeCompetition$ge2.drawNumberList) !== null && _activeCompetition$ge !== void 0 ? _activeCompetition$ge : []).includes(drawNumber);
    competitionName = isDrawIncludedInCompetition ? activeCompetition.getCompetitionDesc() : undefined;
  }
  return {
    betEvents: composedBetEvents,
    drawNumber,
    drawStateId,
    extraMoney: draw.extraMoney,
    href: composeHref(drawNumber, productId),
    icon: competitionName ? 'trophy-tips-sm' : getIconBySportType(sportType),
    league,
    sportType,
    netSale: currentNetSale,
    productId,
    regCloseTime,
    competitionName,
    state,
    title,
    drawId: "".concat(productId, "_").concat(drawNumber)
  };
};
setGlobal('svs.components.tipsen.drawCard.useComposeDrawForDrawCard', useComposeDrawForDrawCard);

 })(window);