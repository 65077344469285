(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.league.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/child-components/draw-card.league.js');
"use strict";


const DrawCardLeague = _ref => {
  let {
    children,
    className
  } = _ref;
  return React.createElement("div", {
    className: "pg_draw_card__league pg_draw_card_component ".concat(className ? className : '')
  }, children);
};
setGlobal('svs.components.tipsen.drawCardComponents.DrawCardLeague', DrawCardLeague);

 })(window);